<template>
  <div class="mt-1 mx-3">
    <book-tags> </book-tags>
  </div>
</template>

<script>
import BookTags from "@/components/profile/book-tags/index.vue";

import { mapActions } from "vuex";

export default {
  props: {
    msg: String,
  },
  methods: {
    ...mapActions("queue", ["notifyQueue"]),
  },
  components: {
    BookTags,
  },
  created() {
    this.notifyQueue({
      text: "Profilo",
      path: "",
      level: 1,
    });
    this.notifyQueue({
      text: "Codici Documenti Contabili",
      path: "book_tags.index",
      level: 2,
    });
  },
};
</script>
