<template>
  <div>
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <details-card
          v-for="(item, index) in detailFields"
          :key="index"
          :header="item.header"
          :edit="item.edit || false"
          :fields="item.fields"
          :beForm="beForm"
          :data="details"
          :repository="item.repository"
        >
        </details-card>
      </div>
      <template #overlay>
        <div class="text-center">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import BaseIcon from "@/components/BaseIcon";
import DetailsCard from "@/components/DetailsCard";

export default {
  mixins: [FormMixin, ShowMixin],
  name: "General",
  props: {
    resourceId: Number,
  },
  components: {
    BaseIcon,
    DetailsCard,
  },
  data() {
    return {
      details: null,
      repository: "insurer",
      allRepositories: ["insurer"],
      mandatoryFields: {},
      isLoading: true,
      generalLabels: null,
      defaultLabels: {
        contacts: {},
      },
      detailFields: {
        scheda_contatto: {
          header: "Scheda Compagnia",
          edit: false,
          repository: "insurer",
          fields: [
            {
              label: this.getDictionary("code", "insurer"),
              value: "code",
            },
            {
              label: this.getDictionary("title", "insurer"),
              value: "title",
            },
          ],
        },
      },
    };
  },
  methods: {
    fetch() {
      //byAddressBook, byCorrespondence
      this.fetchShowForm(this.repository, this.resourceId)
        .then((response) => {
          const data = response.data.data;
          this.details = data;
          this.initDetailCardFormData();
          this.isLoading = false;
        })
        .catch((error) => {
          this.form = {};
          console.error(error);
          this.isLoading = false;
        });
    },
  },
  computed: {},
  mounted() {
    this.isLoading = true;
    this.fetch();
  },
};
</script>
